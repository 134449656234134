(function ($) {
  $(document).on('endeca.typeahead.keyup', function (e, searchTerm, isHeader) {
    var contextHide = isHeader ? '.page-main' : '.page-header',
      contextShow = isHeader ? '.page-header' : '.page-main';

    $('.typeahead-wrapper', $(contextHide)).hide();
    $('.typeahead-wrapper', $(contextShow)).show();
  });

  $(document).on('endeca.typeahead.complete', function (e, hasResults) {
    var $taWrappers = $('.typeahead-wrapper');
    var isMobile = site.client.isMobile;

    if ($taWrappers.length) {
      Drupal.behaviors.grids.attach($taWrappers, Drupal.settings);
    }
    if (Drupal.settings.common.search_feature_revamp && !isMobile) {
      Drupal.behaviors.skuListSizesV1.attach();
      var $parentGrid = $('.typeahead-wrapper .js-grid');
      var $targetGridItemImage = $parentGrid.find('.js-product-brief__img');

      $targetGridItemImage.once().load(function (e) {
        var $items = $parentGrid.find('.js-grid-item');
        var colCountData = $parentGrid.data('grid-items-per-row');

        Drupal.behaviors.grids.equalHeightsRows($items, colCountData);
      });
      Drupal.behaviors.productBriefShadesV1.attach();
    }
  });
})(jQuery);
